export const eBooking = {
   // 获取概况数据
   statistics: '/order/getEBookingStatistics',
   // 获取订单统计
   statisticsByDate: '/order/eBookingStatisticsByDate',
   // 确认预定
   confirmReserve:"/order/confirmOrder",
   // 获取客房预定单汇总表
   orderHotelSummary:"/order/hotel/orderHotelSummary",
   // 客房预定汇总导出
   exportOrderHotelSummary:'/order/hotel/exportOrderHotelSummary',
   // 储值卡余额
   getMemberBalance:"/member/depositCardPay/getMemberBalance",

   // 取消
   cancelOrder:'/order/hotel/cancelOrder',
   // 确认入住
   checkinOrder:"/order/checkinOrder",

}
