<template>
   <section class="cont">
      <!-- 面包屑
      /hotelRes_order/hotel_order 订房订单
      /hotelRes_order/eBooking_reserve 预定订单 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <template v-if="hotelRes_info === 'hotel_order'">
               <el-breadcrumb-item>业务中台</el-breadcrumb-item>
               <el-breadcrumb-item>订单管理</el-breadcrumb-item>
               <el-breadcrumb-item>订房订单</el-breadcrumb-item>
            </template>
            <template v-if="hotelRes_info === 'eBooking_reserve'">
               <el-breadcrumb-item>eBooking</el-breadcrumb-item>
               <el-breadcrumb-item>预定订单</el-breadcrumb-item>
            </template>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>订单状态：</label>
                  <el-button-group>
                     <template v-if="hotelRes_info === 'hotel_order'">
                        <el-button :class="param.orderStatus === '' ? 'bg-gradient' : ''" @click="changeOrderStatus('')"><span>不限</span></el-button>
                        <el-button :class="param.orderStatus === 'PENDING_PAYMENT' ? 'bg-gradient' : ''" @click="changeOrderStatus('PENDING_PAYMENT')"><span>未支付</span></el-button>
                        <el-button :class="param.orderStatus === 'HAVE_PAID' ? 'bg-gradient' : ''" @click="changeOrderStatus('HAVE_PAID')"><span>未入住</span></el-button>
                        <el-button :class="param.orderStatus === 'CHECKIN' ? 'bg-gradient' : ''" @click="changeOrderStatus('CHECKIN')"><span>已入住</span></el-button>
                        <el-button :class="param.orderStatus === 'REFUNDED' ? 'bg-gradient' : ''" @click="changeOrderStatus('REFUNDED')"><span>已取消</span></el-button>
                        <el-button :class="param.orderStatus === 'SUCCESSFUL_TRADE' ? 'bg-gradient' : ''" @click="changeOrderStatus('SUCCESSFUL_TRADE')"><span>已完成</span></el-button>
                     </template>
                     <template v-if="hotelRes_info === 'eBooking_reserve'"> <!-- 待支付 改为 待确认 -->
                        <el-button :class="param.orderStatusList === 'HAVE_PAID,CONFIRM,DECLINE,CHECKIN,CANCEL,REFUNDED' ? 'bg-gradient' : ''" @click="changeOrderStatus('HAVE_PAID,CONFIRM,DECLINE,CHECKIN,CANCEL,REFUNDED')"><span>不限</span></el-button>
                        <el-button :class="param.orderStatusList === 'HAVE_PAID' ? 'bg-gradient' : ''" @click="changeOrderStatus('HAVE_PAID')"><span>待确认</span></el-button>
                        <el-button :class="param.orderStatusList === 'CONFIRM' ? 'bg-gradient' : ''" @click="changeOrderStatus('CONFIRM')"><span>已确认</span></el-button>
                        <el-button :class="param.orderStatusList === 'CHECKIN' ? 'bg-gradient' : ''" @click="changeOrderStatus('CHECKIN')"><span>已入住</span></el-button>
                        <el-button :class="param.orderStatusList === 'CANCEL' ? 'bg-gradient' : ''" @click="changeOrderStatus('CANCEL')"><span>已取消</span></el-button>
                        <el-button :class="param.orderStatusList === 'DECLINE' ? 'bg-gradient' : ''" @click="changeOrderStatus('DECLINE')"><span>已拒单</span></el-button>
                        <el-button :class="param.orderStatusList === 'REFUNDED' ? 'bg-gradient' : ''" @click="changeOrderStatus('REFUNDED')"><span>已退款</span></el-button>
                     </template>
                  </el-button-group>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>预订时间：</label>
                  <el-button-group class="m-right-10">
                     <el-button :class="beginTimeT === '' ? 'bg-gradient' : ''" @click="changeBeginTime('')"><span>不限</span></el-button>
                     <el-button :class="beginTimeT === 'LASTWEEK' ? 'bg-gradient' : ''" @click="changeBeginTime('LASTWEEK')"><span>上周</span></el-button>
                     <el-button :class="beginTimeT === 'TODAY' ? 'bg-gradient' : ''" @click="changeBeginTime('TODAY')"><span>今天</span></el-button>
                     <el-button :class="beginTimeT === 'THISWEEK' ? 'bg-gradient' : ''" @click="changeBeginTime('THISWEEK')"><span>本周</span></el-button>
                     <el-button :class="beginTimeT === 'CUSTOM' ? 'bg-gradient' : ''" @click="changeBeginTime('CUSTOM')"><span>自定义</span></el-button>
                  </el-button-group>
                  <date-packer v-if="beginTimeT === 'CUSTOM'" :datePacker="bookingTimeVal" @setDatePacker="getBookingTime"></date-packer>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>预抵时间：</label>
                  <el-button-group class="m-right-10">
                     <el-button :class="checkInTimeT === '' ? 'bg-gradient' : ''" @click="changeCheckInTime('')"><span>不限</span></el-button>
                     <el-button :class="checkInTimeT === 'LASTWEEK' ? 'bg-gradient' : ''" @click="changeCheckInTime('LASTWEEK')"><span>上周</span></el-button>
                     <el-button :class="checkInTimeT === 'YESTERDAY' ? 'bg-gradient' : ''" @click="changeCheckInTime('YESTERDAY')"><span>昨日</span></el-button>
                     <el-button :class="checkInTimeT === 'TODAY' ? 'bg-gradient' : ''" @click="changeCheckInTime('TODAY')"><span>今天</span></el-button>
                     <el-button :class="checkInTimeT === 'THISWEEK' ? 'bg-gradient' : ''" @click="changeCheckInTime('THISWEEK')"><span>本周</span></el-button>
                     <el-button :class="checkInTimeT === 'NEXTWEEK' ? 'bg-gradient' : ''" @click="changeCheckInTime('NEXTWEEK')"><span>下周</span></el-button>
                     <el-button :class="checkInTimeT === 'CUSTOM' ? 'bg-gradient' : ''" @click="changeCheckInTime('CUSTOM')"><span>自定义</span></el-button>
                  </el-button-group>
                  <date-packer v-if="checkInTimeT === 'CUSTOM'" :datePacker="checkInTimeVal" @setDatePacker="getCheckInTime"></date-packer>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>预订人：</label>
                  <el-input placeholder="预订人" v-model="param.reserveName" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>手机号码：</label>
                  <el-input placeholder="手机号码" v-model="param.reserveMobile" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>订单编号：</label>
                  <el-input placeholder="订单编号" v-model="param.orderNo" clearable></el-input>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>房型：</label>
                  <el-select v-model="param.roomTypeId" clearable :placehoder="$t('msg.client')" >
                     <el-option
                           v-for="item in hotelRoomTypeList"
                           :key="item.id"
                           :label="item.roomTypeName"
                           :value="item.id">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)">搜索</el-button>
               <el-button type="primary" plain @click="handleQuery(false)">重置</el-button>
               <el-button class="bg-gradient" type="primary" plain @click="handleExport">导出</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格 -->
            <el-table
                  ref="mainTable" v-loading="tableLoad" border fit
                  :data="tableData" :stripe="true"
                  @selection-change="handleSelection">
               <!--               <template v-if="hotelRes_info == 'eBooking_reserve'">-->
               <!--                  <el-table-column type="selection" width="55"></el-table-column>-->
               <!--               </template>-->
               <el-table-column label="预订人/订单编号" width="210">
                  <template slot-scope="scope">
                     <p>{{ scope.row.reserveName }}</p>
                     <p>{{ scope.row.orderNo }}</p>
                  </template>
               </el-table-column>
               <el-table-column prop="reserveMobile" label="手机号码" width="115"></el-table-column>
               <el-table-column prop="createTime" label="预订时间" width="160"></el-table-column>
               <el-table-column label="抵离时间" width="180">
                  <template slot-scope="scope">
                     <p>抵 {{ scope.row.checkInTime }}</p>
                     <p>离 {{ scope.row.checkOutTime }}</p>
                  </template>
               </el-table-column>
               <el-table-column prop="roomTypeName" label="房型" width="140"></el-table-column>
               <el-table-column prop="roomNum" label="房间数"></el-table-column>
               <el-table-column prop="actualAmount" :label="hotelRes_info === 'hotel_order' ? '订金' : '实付订金'" width="110"></el-table-column>
               <el-table-column prop="productTotalPrice" label="总房费(元)" width="105"></el-table-column>

               <el-table-column prop="deductionAmount" label="减免金额" width="90"></el-table-column>
               <el-table-column  label="减免原因" width="90">
                  <template slot-scope="scope">
                     <!--                    <span v-if="hotelRes_info === 'hotel_order'">{{ scope.row.mainTitle }} </span>-->
                     <span >{{ scope.row.derateCause }} </span>
                  </template>
               </el-table-column>
               <el-table-column label="状态">
                  <template slot-scope="scope">
                     <span v-if="hotelRes_info === 'hotel_order'">{{ scope.row.orderStatus | filterState(that) }} </span>
                     <span v-if="hotelRes_info === 'eBooking_reserve'">{{ scope.row.orderStatus | filtereBState }} </span>
                  </template>
               </el-table-column>
              <el-table-column label="分账状态" width="100" v-if="hotelRes_info === 'eBooking_reserve'">
                <template slot-scope="scope">
                  <span >{{ scope.row.subAccountState | filterPaymentOrder(that) }} </span>
                </template>
              </el-table-column>
               <el-table-column prop="remark" label="订单备注" width="100"></el-table-column>
               <el-table-column label="操作" fixed="right" min-width="150">
                  <template slot-scope="scope">
                     <el-button  type="text" @click="detailsEvent(scope.row,scope.$index)">
                        <el-link type="primary">详情</el-link>
                     </el-button>
                     <template v-if="hotelRes_info === 'hotel_order'">
                        <el-button
                              v-if="scope.row.orderStatus === 'PENDING_PAYMENT' ||
                                 scope.row.orderStatus === 'PREPAYMENT_FAIL'"
                              type="text" @click="handleCancel(scope.row.orderId,false)">
                           <el-link type="primary">取消</el-link>
                        </el-button>
                        <el-button
                              v-if="scope.row.subAccountState === 'UNFINISHED' ||
                                 scope.row.subAccountState === 'PART_OF_THE_FAILURE'"
                              type="text" @click="finishOrder(scope.row.orderId)">
                           <el-link type="primary">确定完成</el-link>
                        </el-button>
                     </template>
                     <!-- orderStatus ='HAVE_PAID' 待确认-->
                     <template v-if="hotelRes_info === 'eBooking_reserve' && scope.row.orderStatus === 'HAVE_PAID'">
                        <!--<template v-if="hotelRes_info === 'eBooking_reserve' && scope.row.checkInState === 'NOT_CHECKIN'">-->
                        <el-button type="text" @click="handleCancel(scope.row.orderId,true)"><el-link type="primary">拒单</el-link></el-button>
                        <el-button type="text" @click="confirmOrder(scope.row.orderId,'orderConfirm')"><el-link type="primary">订单确认</el-link></el-button>
                     </template>
                     <!-- orderStatus ='CONFIRM' 已确认-->
                     <template v-if="hotelRes_info === 'eBooking_reserve' && scope.row.orderStatus === 'CONFIRM'">
                        <el-button type="text" @click="confirmOrder(scope.row.orderId,'orderCancel')"><el-link type="primary">取消订单</el-link></el-button>
                        <el-button type="text" @click="confirmOrder(scope.row.orderId,'checkInConfirm')"><el-link type="primary">入住确认</el-link></el-button>
                     </template>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
               <!-- 暂时注释 -->
               <!-- <el-button type="primary" @click="operateEvnet" style="margin-top: 20px;"  v-if="hotelRes_info == 'eBooking_reserve'">订单批量确认</el-button>-->
               <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" style="float: right;" v-if="pageshow" />
            </div>
         </el-row>
         <!-- 取消订单 -->
         <el-dialog title="提示" :visible.sync="visibleCancel" width="400px">
            <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
               <p class="tips">确定要{{hotelRes_info === 'hotel_order' ? '取消' : '拒收'}}当前订单吗？</p>
               <el-form-item label="备注" prop="remark">
                  <el-input type="textarea" v-model="ruleForm.remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button size="small" @click="visibleCancel = false">取消</el-button>
               <el-button size="small" class="bg-gradient" type="primary" @click="saveCancel">确定</el-button>
            </span>
         </el-dialog>
         <!-- 订单详情 -->
         <el-dialog class="detail-dialog-box" title="详情" :visible.sync="visibleDetail"  width="60%">
            <el-row class="title t-a-left">
               <h6>订单信息</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">订单号：</label>
                     <span>{{ orderDetail.orderId }}</span>
                  </el-col>
                  <el-col :span="8">
                     <label class="width-100">预订时间：</label>
                     <span>{{ orderDetail.createTime }}</span>
                  </el-col>
                 <el-col :span="8">
                   <label class="width-100">总房费：</label>
<!--                   <span>{{ orderDetail.roomPrice }}</span>-->
                   <span>{{ orderDetail.productTotalPrice }}</span>
                 </el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">订单金额：</label>
                     <span>{{ orderDetail.orderTotalPrice }}</span>
                  </el-col>
                 <el-col :span="8">
                   <label class="width-100">减免金额：</label>
                   <span>{{ orderDetail.deductionAmount }}</span>
                 </el-col>
                 <el-col :span="8">
                   <label class="width-100">减免原因：</label>
                   <span>{{ orderDetail.derateCause || '无'}}</span>
                 </el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">储值卡抵扣金额：</label>
                     <span>{{ orderDetail.depositcardAmount || '无' }}</span>
                  </el-col>
                  <el-col :span="8">
                     <label class="width-100">{{orderDetail.deductionType === 'COUPON_PRESELL' ? '预售' :  orderDetail.deductionType === 'COUPON' ? '优惠' : ''}}券码：</label>
                     <span>{{ orderDetail.couponCode || '无' }}</span>
                  </el-col>
               </el-row>
            </el-row>
            <el-row class="title m-top-10 t-a-left">
               <h6>基本信息</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">预订人：</label>
                     <span>{{ orderDetail.reserveName }}</span>
                  </el-col>
                  <el-col :span="8">
                     <label class="width-100">手机号码：</label>
                     <span>{{ orderDetail.reserveMobile }}</span>
                  </el-col>
                 <el-col :span="8">
                   <label class="width-100">会员等级：</label>
                   <span>{{ orderDetail.medalName || '无' }}</span>
                 </el-col>
               </el-row>

            </el-row>
            <el-row class="title m-top-10 t-a-left">
               <h6>预订房型</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">房型：</label>
                     <span>{{ orderDetail.roomTypeName }}</span>
                  </el-col>
                  <el-col :span="8">
                     <label class="width-100">状态：</label>
                     <span v-if="hotelRes_info === 'hotel_order'">{{ orderDetail.orderStatus | filterState(that) }} </span>
                     <span v-if="hotelRes_info === 'eBooking_reserve'">{{ orderDetail.orderStatus | filtereBState }} </span>
                  </el-col>
                 <el-col :span="8">
                   <label class="width-100">入住天数：</label>
                   <span>{{ orderDetail.checkDays }}</span>
                 </el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">
                     <label class="width-100">抵离时间：</label>
                     <span>{{ reversedTime(orderDetail.checkInTime ) }} - {{ reversedTime(orderDetail.checkOutTime) }}</span>
                  </el-col>
                 <el-col :span="8">
                   <label class="width-100">订单备注：</label>
                   <span>{{ orderDetail.remark || '无' }}</span>
                   <el-button type="text" icon="el-icon-edit" @click="editOrderRemark(orderDetail.id,orderDetail.remark)"></el-button>
                 </el-col>
                 <el-col :span="8">
                   <label class="width-100">预定房间数：</label>
                   <span>{{ orderDetail.roomNum + '(间)' }}</span>
                 </el-col>
               </el-row>

              <el-row class="title m-top-10 t-a-left">
                <h6>分账明细</h6>
              </el-row>
              <el-row class="text-list">
              <el-row>
                <el-col :span="8">
                  <label class="width-100">订单编号：</label>
                  <span>{{  orderDetail.orderNo }}</span>
                </el-col>
                <el-col :span="8">
                  <label class="width-100">客户姓名：</label>
                  <span>{{ orderDetail.reserveName }}</span>
                </el-col>
                <el-col :span="8">
                  <label class="width-100">客户电话：</label>
                  <span>{{orderDetail.reserveMobile }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <label class="width-100">支付时间：</label>
                  <span>{{  orderDetail.payTime }}</span>
                </el-col>
                <el-col :span="8">
                  <label class="width-100">分佣金额：</label>
                  <span>{{ orderDetail.commissionAmount || '无'}}</span>
                </el-col>

              </el-row>
              </el-row>

              <el-row style="margin: 20px 0;">
                <el-table
                    v-loading="detailedLoading"
                    :data="detailedTable"
                    border fit
                    style="width: 100%"
                    :stripe="true"
                    :header-cell-style="{background: '#f5f8f9'}">
                  <el-table-column label="序号" prop="checkIndex" width="55"></el-table-column>
                  <el-table-column label="分佣商品" prop="productName" min-width="130"></el-table-column>
                  <el-table-column label="交易金额" prop="actualAmount" width="100"></el-table-column>
                  <el-table-column label="营销规则" prop="marketName" width="100"></el-table-column>
                  <el-table-column label="分佣类型" prop="level" width="100">
                    <template slot-scope="scope">
                      <span>{{scope.row.level | filterMarketing(that)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="分佣人姓名" prop="memberName" width="150"></el-table-column>
                  <el-table-column label="分佣人电话" prop="mobile" width="150"></el-table-column>
                  <el-table-column label="分佣金额" prop="awardAmount" width="100"></el-table-column>
                  <el-table-column label="订单状态" width="100">
                    <template slot-scope="scope">
                      <span> {{ scope.row.orderStatus | filterOrderStatus(that) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="分账状态" width="110">
                    <template slot-scope="scope">
                      <span>{{ scope.row.state | filterPaymentDetailed(that) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="到账时间" width="170">
                    <template slot-scope="scope" v-if="scope.row.accountTime">
                      <el-row>{{scope.row.accountTime}}</el-row>
                    </template>
                  </el-table-column>
                  <!--               <el-table-column label="操作" width="200" fixed="right">-->
                  <!--                  <template slot-scope="scope">-->
                  <!--&lt;!&ndash;                     <el-button type="text" v-if="scope.row.state === 'FAIL'">&ndash;&gt;-->
                  <!--&lt;!&ndash;                        <el-link type="primary" @click="handleAccount(false,scope.row)">重新分账</el-link>&ndash;&gt;-->
                  <!--&lt;!&ndash;                     </el-button>&ndash;&gt;-->
                  <!--                  </template>-->
                  <!--               </el-table-column>-->
                </el-table>
              </el-row>
            </el-row>
            <span slot="footer" class="dialog-footer">
               <el-button size="small" class="bg-gradient" type="primary" @click="visibleDetail = false">关闭</el-button>
            </span>
         </el-dialog>
         <el-dialog title="修改备注" :visible.sync="orderRemarkDialog"  width="400px">
            <el-form class="form-dialog-box" :model="remarkForm" :rules="rules" ref="orderRemarkForm">
               <el-form-item label="备注" prop="orderRemark">
                  <el-input type="textarea" v-model="remarkForm.orderRemark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button size="small" @click="orderRemarkDialog = false">取消</el-button>
               <el-button size="small" class="bg-gradient" type="primary" @click="submitOrderRemark">确定</el-button>
            </span>
         </el-dialog>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms'
import { eBooking } from "@/api/interface/eBooking"
import {order, reconciliation} from "@/api/interface/business";
import { dateFactory, getDict, exportFile } from '@/common/js/common'
export default {
   data(){
      return{
         hotelRes_info:"",         //订单类型
         that: this,
         tableData: [],           // 表格用户列表
         tableLoad: false,        // 表格加载效果
         total: 0,                // 用户列表总条目数
         action: 'add',           // 当前操作类型
         visibleCancel: false,    // 取消订单会话框
         visibleDetail: false,    // 详情会话框
         orderDetail: {},         // 订单详情
         orderId: '',             // 订单编号
         beginTimeT: '',          // 预订时间类型
         bookingTimeVal: [],      // 预订自定义时间
         checkInTimeT: '',   // 预抵时间类型
         queryCheckInEndTime: '', // 预抵结束时间
         checkInTimeVal: [],      // 预离自定义时间
         hotelRoomTypeList: [],   // 酒店房型列表
         detailedLoading:false,
         detailedTable:[],        // 分账人信息
         ruleForm: {
            remark: '',           // 备注
         },
         orderRemarkDialog:false, //备注修改modal框
         orederRemarkId:'',      //备注id
         remarkForm:{
            orderRemark:'',
         },
         rules: {
            remark: [
               { required: true, message: '请输入取消原因', trigger: 'blur' }
            ],
            orderRemark: [
                 { required: true, message: '请输入备注', trigger: 'blur' }
            ]
         },
         param: {
            hotelId: '',
            orderStatus: '', //订单状态
            orderStatusList:'HAVE_PAID,CONFIRM,DECLINE,CHECKIN,CANCEL,REFUNDED',
            beginTime: '',
            endTime: '',
            queryCheckInTime: '',
            queryCheckInEndTime: '',
            reserveName: '',
            reserveMobile: '',
            orderNo: '',
            // orderId:'',
            roomTypeId: '',
            limit: 1,                // 每页数
            page: 1,                 // 当前页
         },
         pageshow: true,

         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
   },
   watch: {

      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) {
            this.param.hotelId = this.hotelInfo.id
            this.param.queryCheckInTime = dateFactory.dateFormat(false) + ' 00:00:01'
            this.param.queryCheckInEndTime = dateFactory.dateFormat(false) + ' 23:59:59'
            this.getHotelOrder()
            this.getHotelRoomTypeList()
         }
      },
   },
   mounted() {
      this.hotelRes_info = this.$route.params.hotelRes_info
      this.param.hotelId = this.hotelInfo.id
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['order-type','order-status',"sub-account",'marketing-level','payment-state','order-status@splitOrderList'],true,
          {"order-status@splitOrderList":['ALREADY_EVALUATE','CANCEL','CLOSED_TRANSACTION',
              'HAVE_PAID_ABNORMAL','HAVE_TO_RETURN','ALREADY_EVALUATE','CLOSED_TRANSACTION',
              'PENDING_RECEIPT','PREPAYMENT_FAIL','STAY_EVALUATE','SUCCESSFUL_TRADE','TO_BE_SHIPPED',
            ]})
      // 菜单跳转 修改未默认无限
      //this.checkInTimeT = 'TODAY'
      //this.param.queryCheckInTime = dateFactory.dateFormat(false) + ' 00:00:00'
      //this.param.queryCheckInEndTime = dateFactory.dateFormat(false) + ' 23:59:59'
      this.getHotelOrder()
      this.getHotelRoomTypeList()
   },
   methods: {
     //获取表格数据
     getList(row,index){},
      // 详情
      detailsEvent(row,index){
        let param = {
          hotelId:this.hotelInfo.id,
          limit:10,
          page:1,
          orderNo:row.orderNo,  // 订单编号
        }
        //分账明细
        if(this.tabState === "detailed" || row){
          const url = reconciliation.orderCommissionInfoDetail
          param.marketer = this.deptPerson                //分佣人
          param.orderStatus = this.orderState             //订单状态
          param.level = this.deptLevel                    //分佣类型
          param.payBeginTime = this.beginTime             //支付开始时间
          param.payEndTime = this.endTime                 //支付结束时间
          param.beginTime = this.arrivalBeginTime         //到账开始时间
          param.endTime = this.arrivalEndTime             //到账结束时间
          if(row) {
            param.orderNo = row.orderNo
            if(row === 'handleAccount'){
              param.state = this.state  //分账状态
            }
          }
          if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
            param.hotelId = this.storeId
            param.storeId = this.hotelInfo.storeId
          }
          this.$axios.post(url,param).then(res => {
            if (res.success) {
              if(!row){
                this.pageState = true
                this.loading = false
                this.detailedTable = res.records
                this.total = res.total
              }else{
                this.detailedLoading = false
                this.detailedTable = res.records
                if(row !== 'handleAccount'){
                  this.detailedTable.forEach(item=>{
                    item.checkIndex = index+1  //弹窗表格序号
                  })
                }
              }
            }
          })
        }else{
          // //分账订单
          // const url = reconciliation.getShareTheMoneyList
          // param.orderType = this.orderType          //订单类型
          // param.subAccountState = this.state       //分账状态
          // param.orderStatus = this.orderState     //订单状态
          // param.beginTime = this.beginTime       //支付开始时间
          // param.endTime = this.endTime           //支付结束时间
          // if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
          //   param.hotelId = this.storeId
          //   param.storeId = this.hotelInfo.storeId
          // }
          // this.$axios.get(url,param).then(res => {
          //   if (res.success) {
          //     this.pageState = true
          //     this.loading = false
          //     this.tableData = res.records
          //     this.total = res.total
          //   }
          // })
        }
        this.visibleDetail = true;
        this.orderDetail = row;

         // 获取最新储值卡余额 getMemberBalance
         // 获取会员等级
         // const url = urlObj.memberLevels
         // const param = { hotelId: this.hotelInfo.id }
         // this.$axios.post(url, param).then(res => {
         //    if (res.success) {
         //       let data = res.records
         //       this.visibleDetail = true;
         //       this.orderDetail = row;
         //       if(this.orderDetail.memberLevel){
         //          data.forEach( item =>{
         //             if(item.medalCode === this.orderDetail.memberLevel){
         //                this.orderDetail.Level = item.medalName
         //             }
         //          })
         //       }else{
         //          this.orderDetail.Level = '无'
         //       }
         //    }
         // })
         //    const url = eBooking.getMemberBalance
         //    const param = {
         //       hotelId: this.hotelInfo.id,
         //       memberId:row.id
         //    }
         //    this.$axios.post( url , param ).then(res => {
         //       if (res.success) {
         //          console.log(res,'resassadf')
         //       }
         //    })
      },
      // 获取酒店订单列表
      getHotelOrder(){
         const url = order.hotelOrder
         if(this.hotelRes_info === 'eBooking_reserve') {
            delete this.param.orderStatus
            this.param.orderType = 'E_BOOKING'
         }else{
            delete this.param.orderStatusList
         }
         this.param.hotelId = this.hotelInfo.id
         this.$axios.post(url,this.param).then(res => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 改变订单状态
      changeOrderStatus(status) {
         if(this.hotelRes_info === 'hotel_order') {
            this.param.orderStatus = status
         }else{
            this.param.orderStatusList = status
         }
         this.getHotelOrder()
      },
      // 改变预订时间
      changeBeginTime(type) {
         this.beginTimeT = type
         if (this.beginTimeT === '') {
            this.param.beginTime = ''
            this.param.endTime = ''
         } else if (this.beginTimeT === 'LASTWEEK') {
            this.param.beginTime = dateFactory.getWeekDate('last', 'start')
            this.param.endTime = dateFactory.getWeekDate('last', 'end', false) + ' 23:59:59'
         }else if (this.beginTimeT === 'TODAY') {
            this.param.beginTime = dateFactory.dateFormat(false) + ' 00:00:00'
            this.param.endTime = dateFactory.dateFormat(false) + ' 23:59:59'
         }else if (this.beginTimeT === 'THISWEEK') {
            this.param.beginTime = dateFactory.getWeekDate('the', 'start')
            this.param.endTime = dateFactory.getWeekDate('the', 'end', false) + ' 23:59:59'
         } else {
            return
         }
         this.getHotelOrder()
      },
      // 预订自定义时间
      getBookingTime(val) {
         this.param.beginTime = val[0]
         this.param.endTime = val[1]
         this.getHotelOrder()
      },
      // 改变预抵时间
      changeCheckInTime(type) {
         this.checkInTimeT = type
         if (this.checkInTimeT === '') {
            this.param.queryCheckInTime = ''
            this.param.queryCheckInEndTime = ''
         } else if (this.checkInTimeT === 'LASTWEEK') {
            this.param.queryCheckInTime = dateFactory.getWeekDate('last', 'start')
            this.param.queryCheckInEndTime = dateFactory.getWeekDate('last', 'end', false) + ' 23:59:59'
         } else if (this.checkInTimeT === 'YESTERDAY') {
            this.param.queryCheckInTime = dateFactory.getDistanceToday(-1,false) + ' 00:00:01'
            this.param.queryCheckInEndTime = dateFactory.getDistanceToday(-1,false) + ' 23:59:59'
         } else if (this.checkInTimeT === 'TODAY') {
            this.param.queryCheckInTime = dateFactory.dateFormat(false) + ' 00:00:01'
            this.param.queryCheckInEndTime = dateFactory.dateFormat(false) + ' 23:59:59'
         } else if (this.checkInTimeT === 'THISWEEK') {
            this.param.queryCheckInTime = dateFactory.getWeekDate('the', 'start')
            this.param.queryCheckInEndTime = dateFactory.getWeekDate('the', 'end', false) + ' 23:59:59'
         } else if (this.checkInTimeT === 'NEXTWEEK') {
            this.param.queryCheckInTime = dateFactory.getWeekDate('next', 'start')
            this.param.queryCheckInEndTime = dateFactory.getWeekDate('next', 'end', false) + ' 23:59:59'
         } else {
            return
         }
         this.pageshow = false
         this.param.page = 1
         this.$nextTick(()=>{
            this.pageshow = true
         })
         this.getHotelOrder()
      },
      // 预离自定义时间
      getCheckInTime(val) {
         this.param.queryCheckInTime = val[0]
         this.param.queryCheckInEndTime = val[1]
         this.getHotelOrder()
      },
      // 获取酒店房型列表
      getHotelRoomTypeList() {
         const url = pms.pmsRoomTypes
         const param = { hotelId: this.hotelInfo.id }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.hotelRoomTypeList = res.records
            }
         })
      },
      // 搜索会员营销
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getHotelOrder()
         this.param.reserveName = ''
         this.param.reserveMobile = ''
         this.param.orderNo = ''
         this.param.roomTypeId = ''
         this.param.orderStatus = ''
         this.param.orderStatusList = 'HAVE_PAID,CONFIRM,DECLINE,CHECKIN,CANCEL'
         this.beginTimeT = ''
         this.checkInTimeT = 'TODAY'
         this.param.beginTime = ''
         this.param.endTime = ''
         this.param.queryCheckInTime = dateFactory.dateFormat(false) + ' 00:00:01'
         this.param.queryCheckInEndTime = dateFactory.dateFormat(false) + ' 23:59:59'
         // this.param.orderId = ''
         this.getHotelOrder()
      },
      //订单备注修改
      editOrderRemark(id,remark){
         this.orederRemarkId = id
         this.orderRemarkDialog = true
         this.orderRemark = remark
      },
      //提交备注
      submitOrderRemark(){
  this.$refs.orderRemarkForm.validate(valid => {
            if (valid) {
               let url = '/order/modify'
               let param = { id: this.orederRemarkId, remark: this.remarkForm.orderRemark}
               this.$axios.post(url, param).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: '修改成功！',
                        type: 'success'
                     })
                     this.orderRemarkDialog = false
                     this.orderDetail.remark = this.remarkForm.orderRemark
                     this.remarkForm.orderRemark = ''
                  }
               })
            }
         })
      },
      // 改变每页数
      pageChange(num){
         this.param.limit = num
         this.getHotelOrder()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.param.page = num
         this.getHotelOrder()
      },
      // 取消预订单  // 拒单
      handleCancel(orderId,bool) {
         this.orderId = orderId
         this.visibleCancel = true
         if(bool) this.ruleForm.remark = '客房已满'
         // eBooking.eBcancelReserve 预订拒单
      },
      // 保存取消
      saveCancel() {
         this.$refs.ruleForm.validate(valid => {
            if (valid) {
               let url = order.cancelHotelOrder
               let param = { orderId: this.orderId, remark: this.ruleForm.remark,orderStatus:'CANCEL' }
               if( this.hotelRes_info === 'eBooking_reserve') param.orderStatus = 'DECLINE'
               this.$axios.post(url, param).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: '取消成功！',
                        type: 'success'
                     })
                     this.visibleCancel = false
                     this.getHotelOrder()
                  }
               })
            }
         })
      },
      // 确定完成
      finishOrder(orderId) {
         this.$confirm('订单确定标记为完成吗？确定完成后，' +
               '订单对应的分佣和奖励将进行核算。', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = order.finishHotelOrder
            const param = { orderId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     type: 'success',
                     message: '订单完成!'
                  });
                  this.getHotelOrder()
               }
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消操作'
            });
         });
      },
      // 导出文件
      handleExport() {
         const url = order.orderHotelsExcel
         let fileName = '订房订单'
         if( this.hotelRes_info === 'eBooking_reserve') {
            this.param.limit = 999
            this.param.orderType = 'E_BOOKING'
            fileName = '预定订单'
         }
         this.$axios.get(url, this.param, {}, 'blob').then(res => {
            exportFile(res,fileName)
            this.param.limit = sessionStorage.getItem('pageSize') * 1 // 重置每页条数
         })
      },
      // 截取时间
      reversedTime(value) {
         if (value) return value.split(' ')[0]
      },
      // 订单确认 20210929092732111295252
      confirmOrder(orderId,type){
         let param = { orderId:orderId }
         let title = ''
         if(type === 'orderConfirm') title = '确定接收所选订单吗?'
         if(type === 'orderCancel') {
            param.orderStatus = 'CANCEL'
            title = '确定取消所选订单吗?'
         }
         if(type === 'checkInConfirm') title = '此为入住确认，是否确认?'
         this.$confirm(title, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let eBookingUrl = ''
            if(type === 'orderConfirm') eBookingUrl = eBooking.confirmReserve
            if(type === 'orderCancel') eBookingUrl = eBooking.cancelOrder
            if(type === 'checkInConfirm') eBookingUrl = eBooking.checkinOrder
            let url = eBookingUrl
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.getHotelOrder()
                  this.$message({ type: 'success', message: '确定成功!' });
               }
            })
         }).catch(() => {
            this.$message({ type: 'info', message: '已取消确定'});
         });
      },
      //多选框选中值
      handleSelection(val){
         console.log(val,'多选框选中值')
      },
      // 暂时注释
      // 订单批量确认
      // operateEvnet(){
      //    this.$confirm('确定接收所选订单吗？?', '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //    }).then(() => {
      //       // const url = urlObj
      //       // const param = {  }
      //       // this.$axios.post(url, param).then(res => {
      //       //    if (res.success) {
      //             console.log('订单批量确认')
      //             this.getHotelOrder()
      //             this.$message({
      //                type: 'success',
      //                message: '确定成功!'
      //             });
      //       //    }
      //       // })
      //    }).catch(() => {
      //       this.$message({
      //          type: 'info',
      //          message: '已取消确定'
      //       });
      //    });
      // },
   },
   filters: {
      filterState(val, that) {
         if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
      },
     filterMarketing(val,that){
       if (val && that.dictData['marketing-level']) return that.dictData['marketing-level'][val] || val
     },
     filterOrderType(val,that) {
       if(val === 'E_BOOKING') return "客房"
       if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
     },
     //订单状态
     filterOrderStatus(val,that){
       if(val === 'CONFIRM') return '已确认'
       if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
     },
     // 订单分账状态
     filterPaymentOrder(val,that){
       if (val && that.dictData['sub-account']) return that.dictData['sub-account'][val] || val
     },
     // 明细分账状态
     filterPaymentDetailed(val,that){
       if (val && that.dictData['payment-state']) return that.dictData['payment-state'][val] || val
     },
      filtereBState(val){
         switch (val){
            case 'HAVE_PAID' :
               return '待确认'
            case 'CONFIRM' :
               return '已确认'
            case 'DECLINE' :
               return '已拒单'
            case 'CHECKIN':
               return '已入住'
            case 'CANCEL':
               return '取消'
           case 'REFUNDED':
               return '已退款'
         }
      }
   },
}
</script>

<style lang="scss" scoped>
.cont{
   .form-dialog-box{
      /deep/ .el-form-item{
         > label{ width: 60px!important }
         > div{ margin-left: 60px }
      }
      .tips{ line-height: 40px; text-align: center; font-size: 16px; margin-bottom: 20px }
   }
  .split-dialog-box{
    margin-bottom: 30px;
  }
}
</style>
