export const pms = {
   // 获取房间列表
   pmsRoomList: '/pms/room/getList',
   // 房态统计
   housingStatistics: '/pms/room/housingStatistics',
   // 获取房型列表
   pmsRoomTypes: '/pms/roomtype/getList',
   //修改ebooking房型状态
   updateebookingState:'/pms/roomtype/updateState',
   // 今日抵达/预离
   arriveDepartureTotal: '/order/orderRowhouse/arriveDepartureTotal',
   // 办理入住
   pmsCheckIn: '/order/orderRowhouse/checkIn',
   // 办理退房
   pmsCheckOut: '/order/orderRowhouse/checkOut',
   // 办理换房
   pmsExchangeRoom: '/order/orderRowhouse/roomChange',
   // 设置保留
   pmsSetRetain: '/pms/room/setRetain',
   // 更改房间状态
   changRoomState: '/pms/room/changRoomState',
   // 取消预订
   cancelReserve: '/order/orderRowhouse/cancelReserve',
   //获取预订单
   getReserve: "/pms/reserveRoomType/getPage",
   //创建预定单
   createReserve: "/pms/reserve/create",
   //编辑预定单
   updateReserve: "/pms/reserve/edit",
   //取消预定单
   getReserveCancel: '/pms/reserveRoomType/cancel',
   //获取房间列表分页
   getroomlistpage: '/pms/room/getPage',
   // //获取房间列表分页
   getroomlist: '/pms/room/getList',
   //新增房型
   addroomtype: '/pms/roomtype/add',
   //批量添加房间
   addroomlistdata: '/pms/room/addRooms',
   //删除房型
   deleteroomtype: '/pms/roomtype/delete/',
   //添加房间
   addroomlist: '/pms/room/add',
   //获取房价策略中的房型列表
   getRoomTypePriceList: '/pms/roomtype/getRoomTypePriceList',
   //修改房型
   editroomtype: '/pms/roomtype/edit',
   //房型详情
   getroomtypedetails: '/pms/roomtype/get/',
   //校验是否能删除楼层
   checkRoomCount: '/pms/room/checkRoomCount',
   //删除房间
   deletelroom: '/pms/room/delete/',
   //修改房间
   editroomitem: '/pms/room/editRoom',
   //获取价格信息
   getCalenderPrice: "/pms/priceCalendar/getPriceCalendarList",
   // 修改房价日历价格信息
   updateCalenderPrice: "/pms/priceCalendar/saveCustomPrice",
   // 批量修改房价/房量信息
   updateCalenderPrices: "/pms/priceCalendar/saveBatchCustomPrice",
   //获取房价策略
   getHPStrategy: "/pms/strategyPrice/getPage",
   //新增房价策略
   getAHPStrategy: "/pms/strategyPrice/save",
   //修改房价策略状态
   housePriceStop: '/pms/strategyPrice/updateState',
   //订单来源
   getorderSource: '/system/dict/getSubDict/order-source',
   //选择房型排房
   getRowRoomList: "/pms/roomtype/getRowRoomList",
   //获取入住房号
   getRowHouseList: '/pms/reserveRoomType/getRowHouseList',
   //选择排房
   selectRowHouse: '/pms/orderRowhouse/rowHouse',
   //获取编辑预定
   getReserveDetailInfo: "/pms/reserve/getReserveDetailInfo",
   //预定未排房
   rowHouse: "/order/orderRowhouse/rowHouse",
   // 预定转入住
   reserveToCheckIn: '/order/orderRowhouse/reserveToCheckIn',

   //查询价格模板列表
   getpriceTemplate:"/pms/priceTemplate/getList",
   //查询管理人员
   getUnBindEmployeeList:'/hotel/agreementUnitEmployee/getUnBindAgreementUnitEmployeeList',
   //保存价格模板
   savepriceTemplate:"/pms/priceTemplate/save",
   //删除模板
   deletepriceTemplate:"/pms/priceTemplate/delete/",
   //查询价格日历
   getPriceTemplateDataList:"/pms/priceTemplateData/getPriceTemplateDataList",
   //自定义修改价格模板
   savePriceTemplateData:"/pms/priceTemplateData/savePriceTemplateData",
   //批量修改价格模板
   saveBatchPriceTemplateData:"/pms/priceTemplateData/saveBatchPriceTemplateData",

   // 添加房型
   addRoomType:"/pms/roomtype/addPms",
   // 编辑房型
   editRoomType:"/pms/roomtype/editPms",
   // 房型列表 分页
   getRoomTypePage:"/pms/roomtype/getPage",
   // 删除房型
   delRoomType:"/pms/roomtype//delete/",
   // 房型列表
   getRoomTypeList:"/pms/roomtype/getList",

   // 钟点房-添加
   addHourRoom:"/pms/hourlyroom/add",
   // 钟点房-编辑
   editHourRoom:"/pms/hourlyroom/edit",
   // 钟点房列表 分页
   getHourRoom:"/pms/hourlyroom/getPage",
   // 钟点房-删除
   delHourRoom:"/pms/hourlyroom/delete/",
   // 设置钟点房夜量
   setHourlyRoomNightVolume:"/hotel/setHourlyRoomNightVolume",

   // 房价管理-查询
   getRoomPrice:"/pms/roomStatus/getRoomPriceCalendar",
   // 房价管理-修改房价
   setRoomPrice:"/pms/priceCalendar/saveBatchPMSCustomPrice",

   // 包栋设置-添加
   addbundleSetting:'/pms/roomPackage/add',
   // 包栋设置-分页
   getBundleSettingPage:'/pms/roomPackage/getPage',
   // 包栋设置-删除
   delBundleSetting:'/pms/roomPackage/delete/',
   // 包栋设置-修改
   editBundleSetting:'/pms/roomPackage/edit',

   // 改价记录
   getChangeRecord:"/pms/priceCalendarRecord/getPage",
   // 改价记录-导出
   getChangeRecordExport:"/pms/priceCalendarRecord/export",

   // 房间分组设置-未分组房间
   getNotRoomGroup:"/pms/roomGroup/getNotRoomGroupList",
   // 房间分组设置-保存分组
   conserveRoomGroup:"/pms/roomGroup/operationRoomGroup",
   // 房间分组设置-分组列表
   getRoomGroup:"/pms/roomGroup/getList",

   // 楼层分组设置-未分组房间
   getNotFloor:'/pms/floor/getNotFloorList',
   // 楼层分组设置-保存分组
   conserveFloorGroup:'/pms/floor/operationFloor',
   // 楼层分组设置-分组列表 - 分页
   getFloorGroup:'/pms/floor/getPage',

   // 房情表
   getRoomSituation:"/pms/roomStatus/getRoomSituation",
}

import request from "@/api/service";

export function getChangeRecordExport(params) {
   return request({
      url:pms.getChangeRecordExport,
      method: 'GET',
      params,
      responseType: 'blob'
   })
}